import React, { useEffect, useState } from "react";
import axios from "axios";
import { useSearchParams } from "react-router-dom";
import { baseURL } from "../../../utils/constant";
import { getCookie } from "../../../utils/auth";

const StripeSuccess = () => {
  const [searchParams] = useSearchParams();
  const sessionId = searchParams.get("session_id");
  const [paymentDetails, setPaymentDetails] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchPaymentDetails = async () => {
      try {
        const response = await axios.get(
          `${baseURL}payment/saveStripeLogsByUserId?session_id=${sessionId}`,
          {
            headers: {
              Authorization: `Bearer ${getCookie("bearerToken")}`,
              session_id: sessionId,
            },
          }
        );
        //available amount = response.data.userCredits
        // update the credit from state accordingly
          
        setPaymentDetails(response.data);

      } catch (err) {
        setError("Failed to fetch payment details. Please try again later.");
      }
    };

    if (sessionId) {
      fetchPaymentDetails();
    }
  }, [sessionId]);

  if (error) {
    return <div>{error}</div>;
  }

  if (!paymentDetails) {
    return <div>Loading payment details...</div>;
  }

  return (
    <div>
    {paymentDetails.url && (
      <iframe
      src={paymentDetails.url}
      frameBorder="0"
      width="100%"
      height="600px"
      />
    )}
    </div>

  );
};

export default StripeSuccess;
